import commonHelper from '@/app/utility/common.helper.utility';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import addPartyOnBoarding from './addPartyOnBoarding';
import partyDetails from './partyDetails';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../utility/url.utility';
import DatePicker from 'vue2-datepicker';
import termsCondition from './partyDetails/termsCondition';
export default {
  name: 'partyOnBoarding',
  watch: {
    currentPage: function() {
      this.getPartyOnBoardingDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPartyOnBoardingDetails();
    }
  },
  components: {
    addPartyOnBoarding,
    partyDetails,
    DatePicker,
    HistoryRecord,
    ApprovalHistory,
    termsCondition
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      loader: false,
      showPartyOnBoardingModal: false,
      showPartyDetailsModal: false,
      partyOnBoardingData: [],
      partyOnBoardingFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'vendor_code'
        },
        {
          key: 'party_name'
        },
        {
          key: 'pan',
          label: 'PAN'
        },
        {
          key: 'email'
        },
        {
          key: 'mob',
          label: 'Contact Number'
        },
        {
          key: 'int_contact_name',
          label: 'Buyer Name'
        },
        {
          key: 'int_contact_email',
          label: 'Buyer Email'
        },
        {
          key: 'int_contact_mob',
          label: 'Buyer Number',
          class: 'text-center'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'email_sent_status_meaning',
          label: 'Email Status'
        },
        {
          key: 'email_resend'
        }
      ],
      partyName: null,
      panNumber: null,
      appStatus: { value: null, text: null },
      internalContact: { value: null, text: null },
      fromDate: null,
      toDate: null,
      partyRowDetails: null,
      showDeleteBtn: false,
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      showValueSetModal: false,
      partyReqIdDtl: null,
      recordId: null,
      showDownloadModal: false,
      downloadDocVendor: URL_UTILITY.downloadDocUrl,
      downloadData: [
        { original_file_name: 'DELHIVERY_VENDOR_APPROVAL.pdf' },
        { original_file_name: 'DELHIVERY_VENDOR_ONBOARD.pdf' },
        { original_file_name: 'DELIVERY_VENDOR_REGISTRATION.pdf' }
      ],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        }
      ],
      wfMenuId: null,
      eventId: null,
      userId: this.$store.state.auth.userId,
      totalPayload: null,
      showTermsConditionModal: false,
      requestId: null,
      templateId: null,
      requestNumId: null,
      downloadFlag: false,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: true,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      },
      showExcelUploadModal:false,
      matchingStatus:null,
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHidePartyOnBoardingModal(true);
          this.partyRowDetails = null;
        }
        if (actionName === 'download') {
          if (!this.showPartyOnBoardingModal && !this.showTermsConditionModal) {
            this.showHideDownloadModal(true);
          } else {
            if (this.showTermsConditionModal) {
              this.getTemplateDetails('Vendor_Terms_Conditions_temp');
            } else {
              alert('Not Allowed');
            }
          }
        }
        if (
          actionName === 'upload' &&
          !this.showPartyOnBoardingModal &&
          !this.showTermsConditionModal
        ) {
            this.showExcelUploadModal = true;
          }
      }
    });
  },
  methods: {
    downloadExcelFile() {
      this.loader = true;
      /**
       * @param(payload, 'action name', 'file name')
       */
      const downloadpayload = { ...this.totalPayload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'intWorkflow/getPartyOnBoardingDetails',
        'Party-OnBoarding',
        () => (this.loader = false)
      );
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRows;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPartyOnBoardingDetails', this.totalPayload)
        .then(resp => {
          this.loader = false;
          const result = resp.data.data.page;
          const customDownloadData = result.map(obj => ({
            approval_status: obj.approval_status,
            vendor_code: obj.vendor_code,
            party_name: obj.party_name,
            pan: obj.pan,
            email: obj.email,
            contact_number: obj.mob,
            buyer_name: obj.int_contact_name,
            buyer_email: obj.int_contact_email,
            buyer_number: obj.int_contact_mob,
            creation_date: obj.creation_date
              ? new Date(obj.creation_date)
              : null,
            email_status: obj.email_sent_status_meaning
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, 'Party-OnBoarding');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    resendEmail(item) {
      if (!item.email_sent_status) {
        this.getHistory(item);
      } else {
        this.resendEmailPartyonboarding(item);
      }
    },
    getHistory(item) {
      this.loader = true;
      const payload = {
        event_id: 32,
        record_id: item.req_id
      };
      this.$store
        .dispatch('intWorkflow/processWorkflow', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const historyList = response.data.data;
            if (historyList.length) {
              this.resendEmailPartyonboarding(item);
            }
          } else {
            alert(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    resendEmailPartyonboarding(item) {
      const payload = {
        party_req_id: item.req_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/resendEmailPartyonboarding', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            alert('Email Sent Successfully');
          } else {
            alert(resp.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMenuId() {
      this.$store
        .dispatch('intWorkflow/getMenuIdForPartyOnBoarding')
        .then(response => {
          if (response.status === 200) {
            this.wfMenuId = response.data.data.menu_id;
            this.getEventId();
          }
        });
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', this.wfMenuId)
        .then(response => {
          if (response.status === 200) {
            this.eventId = response.data.data;
          }
        });
    },
    showHideHistory(flag, historyType, type) {
      this.showHistory = flag;
      this.historyType = historyType;
      if (type == 'dropdown') {
        this.recordId = this.partyRowDetails.req_id;
      }
    },
    showHideDownloadModal(flag) {
      this.showDownloadModal = flag;
    },
    deleteVendor() {
      const promt = confirm('Are you sure, you want to delete this vendor?');
      if (promt) {
        const payload = {
          party_req_id: this.partyReqIdDtl
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/deleteVendor', payload)
          .then(resp => {
            this.loader = false;
            this.showAlert = true;
            if (resp.status === 200) {
              this.isSuccess = true;
              this.responseMsg = 'Record Deleted Successfully';
              this.getPartyOnBoardingDetails();
              this.showDeleteBtn = false;
            } else {
              this.isSuccess = false;
              this.responseMsg = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    getPartyOnBoardingDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        user_id: this.userId,
        party_name: this.partyName,
        pan: this.panNumber,
        approval_status: this.appStatus.value,
        int_contact_name: this.internalContact.text,
        start_date: this.fromDate,
        end_date: this.toDate
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPartyOnBoardingDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.partyOnBoardingData = resp.data.data.page;
            this.partyOnBoardingData.map(data => {
              data.email_resend = 'Resend';
              return data;
            });
            this.totalRows = resp.data.data.total_elements;
            this.getMenuId();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedPORow(item) {
      if (item.approval_status === 'DRAFT') {
        this.partyReqIdDtl = item.req_id;
        this.showDeleteBtn = true;
      } else {
        this.showDeleteBtn = false;
      }
      this.showHistoryBtn = true;
      if (this.showHistoryBtn) {
        this.recordId = item.req_id;
      } else {
        this.recordId = null;
      }
    },
    clearFilters() {
      this.partyName = null;
      this.panNumber = null;
      this.appStatus = { value: null, text: null };
      this.internalContact = { value: null, text: null };
      this.fromDate = null;
      this.toDate = null;
      this.partyOnBoardingData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.showDeleteBtn = false;
      this.showHistoryBtn = false;
    },
    rowSelected(item) {
      this.partyRowDetails = item;
      this.showHidePartyDetailsModal(true);
    },
    upperCaseFunction(panNumber) {
      this.panNumber = panNumber.toUpperCase();
    },
    showHidePartyOnBoardingModal(flag) {
      this.showPartyOnBoardingModal = flag;
    },
    showHidePartyDetailsModal(flag) {
      this.showPartyDetailsModal = flag;
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        this.appStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
        // this.$store.dispatch('intWorkflow/setApprovalStatus', this.appStatus)
        // this.$store.commit('intWorkflow/setAppStatus', this.appStatus)
      } else if (this.vsetCode === appStrings.VALUESETTYPE.Select_Employee) {
        this.internalContact.text = item.value_code;
        this.internalContact.value = item.value_set_dtl_id;
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.fromDate);
    },
    showHideTermsConditionModal(flag) {
      this.showTermsConditionModal = flag;
    },
    getTermsCondition() {
      const payload = {
        party_req_id: this.partyRowDetails.req_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getTermsCondition', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result) {
              this.termsCondition = result.terms;
              this.clauseLabel1 = result.disclosure_text1;
              this.clauseLabel2 = result.disclosure_text2;
              this.clauseLabel3 = result.disclosure_text3;
              this.clauseStatus1 = result.disclosure_status1;
              this.clauseStatus2 = result.disclosure_status2;
              this.clauseStatus3 = result.disclosure_status3;
              this.clauseReason1 = result.disclosure_reason1;
              this.clauseReason2 = result.disclosure_reason2;
              this.clauseReason3 = result.disclosure_reason3;
              this.signedOn = result.disclosure_creation_date;
            } else {
              alert('No Terms and Condition Found');
              this.showTermsConditionModal = false;
            }
          }
        })
        .catch(() => {
          this.loader = false;
          alert('Something went wrong');
        });
    },
    getTemplateDetails(tmpName) {
      const payload = {
        _page: 0,
        _limit: 10,
        template_name: tmpName
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getTemplateDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.requestId = resp.data.data.page[0].request_id;
            this.templateId = resp.data.data.page[0].template_id;
            this.saveSubmitRequest(tmpName);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest(tmpName) {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: tmpName=='BULK_UPLOAD_VENDOR_TMP'? 'BLANK':'PDF', //pass hardcode
            no_of_args: 25,
            request_parameter: tmpName=='BULK_UPLOAD_VENDOR_TMP'? null :this.partyRowDetails.req_id,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: tmpName=='BULK_UPLOAD_VENDOR_TMP'? null :this.partyRowDetails.req_id,
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      if(tmpName !=='BULK_UPLOAD_VENDOR_TMP'){
        this.downloadFlag = true;
      this.loader = true;
      }
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          this.downloadFlag = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest(tmpName);
          }
        })
        .catch(() => {
          this.loader = false;
          this.downloadFlag = false;
        });
    },
    getSubmitRequest(tmpName) {
        this.loader = true;
      this.$store
        .dispatch('template/getSubmitRequest', this.requestNumId)
        .then(resp => {
          if(tmpName =='BULK_UPLOAD_VENDOR_TMP'){
            this.loader = false;
          }
          if (resp.status === 200) {
            this.matchingStatus = resp.data.data[0].status;
            this.reqId = resp.data.data[0].id;
            if (this.matchingStatus === 'COMPLETED') {
              this.loader = false;
              if(tmpName=='BULK_UPLOAD_VENDOR_TMP'){
                this.requestNumId=null;
                this.reqId=null;
                this.requestId=null;
                this.getPartyOnBoardingDetails()
              }else{
                window.location.href = this.dowloadReport + '/' + this.reqId;
              }
            } else if (this.matchingStatus === 'ERROR') {
              this.loader = false;
              alert('Request in ERROR can not Preview');
              this.requestId=null;
            } 
            else if(tmpName !=='BULK_UPLOAD_VENDOR_TMP') {
              setTimeout(() => {
                this.getSubmitRequest(tmpName);
              }, 3000);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideExcelUpload(flag) {
      this.showExcelUploadModal = flag;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
